<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{path: '/raceManagement/ibcHomeManagement'}">IBC赛务管理</el-breadcrumb-item>
      <el-breadcrumb-item>考场详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span>信息筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="80px"
        ref="queryFormRef"
      >
        <el-row :gutter="50">
           <el-col :span="6">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="queryForm.phone"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="姓名" prop="userName">
              <el-input
                v-model="queryForm.userName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button type="primary" style="width: 100%" @click="getDataList"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
        <!-- <el-col :span="2">
          <el-button type="primary" style="width: 100%" @click="addRoom"
            >新增</el-button
          >
        </el-col> -->
      </el-row>
    </el-card>
        <!-- 考场信息列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="12">
          <span
            style="
              display: -moz-inline-box;
              display: inline-block;
            "
            >考场名称：{{matchRoomName}}</span
          >
          <span style="color: blue; font-size: 14px; margin-left: 10px;">{{
            "已添加" + total + "人"
          }}</span>
        </el-col>
        <el-col :span="12">
          <div style="text-align: right;">
            <el-button type="primary" @click="exportDownIbcStudentsListForm"
              >导出裁判纸</el-button
            >
            <el-button type="primary" @click="addInfoDialogClick"
              >添加考生</el-button
            >
          </div>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="姓名" prop="userName"></el-table-column>
        <el-table-column
          label="性别"
          prop="sex"
          :formatter="genderFormatter"
        ></el-table-column>
        <el-table-column label="国籍" prop="country"></el-table-column>
        <el-table-column label="电话" prop="phone"></el-table-column>
        <el-table-column label="省市区" prop="provence" width="200px">
                  <template scope="scope" >
                      <span v-if="scope.row.province"
                          >{{ scope.row.province }}/{{
                              scope.row.city
                          }}/{{ scope.row.area }}</span
                      >
                      <span v-else>未设置</span>
                  </template>
              </el-table-column>
        <el-table-column label="学校" prop="schoolName"></el-table-column>
        <el-table-column
          label="组别"
          prop="enrollmentLevel"
          :formatter="userGroupFormatter"
        >
        </el-table-column>
        <!-- <el-table-column
          label="年级"
          prop="grade"
          :formatter="gradeFormatter"
        ></el-table-column> -->
        <!-- <el-table-column label="班级" prop="classes"></el-table-column> -->
        <el-table-column label="座位号" prop="userSerialNo"></el-table-column>
        <el-table-column label="邮箱" prop="email"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="small"
              @click="showEditDialog(scope.row)"
              >移动</el-button
            >
            <el-button
              type="text"
              icon="el-icon-edit"
              size="small"
              @click="delecteEditDialog(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
     <!-- 移动考生信息的dialog -->
    <el-dialog
      title="移动考生"
      :visible.sync="editInfoDialogVisible"
      width="40%"
      @close="editInfoDialogClose"
    >
      <el-form
        :model="editInfoForm"
        label-width="150px"
        ref="editInfoFormRef"
        :rules="editInfoRules"
      >
        <el-form-item label="届" prop="ibcSession" style="width: 80%"
>
          <el-select
            placeholder="请选择"
            style="width: 100%"
            v-model="editInfoForm.ibcSession"
            disabled
          >
            <el-option
              v-for="item in dict_ibcSession"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="赛季" prop="ibcSeason" style="width: 80%"
>
          <el-select
            placeholder="请选择"
            style="width: 100%"
            v-model="editInfoForm.ibcSeason"
            disabled
          >
            <el-option
              v-for="item in dict_ibcSeason"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="轮次" prop="ibcRound" style="width: 80%"
>
          <el-select
            placeholder="请选择"
            style="width: 100%"
            v-model="editInfoForm.ibcRound"
            disabled
          >
            <el-option
              v-for="item in dict_ibcRound"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="房间名" prop="newHallId" style="width: 80%"
>
          <el-select
            placeholder="请选择"
            style="width: 100%"
            v-model="editInfoForm.newHallId"
          >
            <el-option
              v-for="item in roomListData"
              :key="item.id"
              :label="item.hallName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editInfoDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editInfo">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 添加考生信息的dialog -->
    <el-dialog
      :title="'添加考生（' + groupName + ')'"
      :visible.sync="addInfoDialogVisible"
      @close="addInfoDialogClickClose"
      width="60%"
    >
      <el-form
        :model="addInfoForm"
        label-width="150px"
      >
        <el-table
        header-cell-class-name="tableHeaderStyle"
        ref="addInfoTableRef"
        :data="examineeDataList"
        @selection-change="handleSelectionChange"
        border
        stripe
      >
        <el-table-column
          type="selection"
           width="70px"
           header-align="center"
           align="center"
           >
        </el-table-column>
        <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="姓名" prop="userName"></el-table-column>
        <el-table-column label="国籍" prop="country"></el-table-column>
        <el-table-column
          label="性别"
          prop="sex"
          :formatter="genderFormatter"
        ></el-table-column>
        <el-table-column label="省市区" prop="provence">
                  <template scope="scope" >
                      <span v-if="scope.row.province"
                          >{{ scope.row.province }}/{{
                              scope.row.city
                          }}/{{ scope.row.area }}</span
                      >
                      <span else>未设置</span>
                  </template>
              </el-table-column>
        <el-table-column label="学校" prop="schoolName"></el-table-column>
        <el-table-column
          label="组别"
          prop="enrollmentLevel"
          :formatter="userGroupFormatter"
        >
        </el-table-column>
        <!-- <el-table-column
          label="年级"
          prop="grade"
          :formatter="gradeFormatter"
        ></el-table-column> -->
        <!-- <el-table-column label="班级" prop="classes"></el-table-column> -->
        <el-table-column label="联系方式" prop="phone"></el-table-column>
        <el-table-column label="邮箱" prop="email"></el-table-column>
      </el-table>
      <!-- 分页 -->
      <!-- <el-pagination
        @size-change="addHandleSizeChange"
        @current-change="addHandleCurrentChange"
        :current-page="addInfoForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="addInfoForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="addTotal"
      >
      </el-pagination> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addInfoDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveAddInfoClick">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getIbcRoomUser, getIbcSignUpUser, addIbcRoomUser, deleteIbcRoomUser, getIbcRoomList, moveIbcRoomUser } from '@/http/api'
export default {
  data () {
    return {
      roomData: {},
      groupName: '',
      total: 0,
      addTotal: 0,
      dataList: [],
      examineeDataList: [],
      roomListData: [],
      editInfoDialogVisible: false,
      addInfoDialogVisible: false,
      matchRoomName: '',
      queryForm: {
        phone: '',
        userName: '',
        hallId: null,
        pageNum: 1,
        pageSize: 10
      },
      editInfoForm: {
        activityType: this.$chnEngStatusCode.ibcActivityType,
        ibcSession: null,
        ibcSeason: null,
        ibcRound: null,
        hallLevel: null,
        id: null,
        enrollmentId: null,
        oldHallId: null,
        newHallId: null
      },
      addInfoForm: {
        activityType: this.$chnEngStatusCode.ibcActivityType,
        enrollmentId: [],
        ibcSession: null,
        ibcSeason: null,
        ibcRound: null,
        hallId: null,
        hallLevel: null
      },
      editInfoRules: {
        ibcSession: [{ required: true, message: '请选择', trigger: 'change' }],
        ibcSeason: [{ required: true, message: '请选择', trigger: 'change' }],
        ibcRound: [{ required: true, message: '请选择', trigger: 'change' }],
        hallLevel: [{ required: true, message: '请选择', trigger: 'change' }],
        newHallId: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      dict_ibcSeason: this.$userInfo.getDataList(this, 'ibc_season').then(value => { this.dict_ibcSeason = value }),
      dict_sspcnGroup: this.$userInfo.dict_group(),
      dict_ibcSession: this.$userInfo.getDataList(this, 'ibc_session').then(value => { this.dict_ibcSession = value }),
      dict_ibcRound: this.$userInfo.getDataList(this, 'ibc_round').then(value => { this.dict_ibcRound = value }),
      dict_gender: this.$userInfo.getDataList(this, 'e_sex').then(value => { this.dict_gender = value })

    }
  },
  created () {
    this.roomData = JSON.parse(this.$route.query.roomData)
    this.queryForm.hallId = this.addInfoForm.hallId = this.editInfoForm.oldHallId = this.roomData.id
    this.editInfoForm.ibcSession = this.addInfoForm.ibcSession = this.roomData.ibcSession + ''
    this.editInfoForm.ibcSeason = this.addInfoForm.ibcSeason = this.roomData.ibcSeason + ''
    this.editInfoForm.ibcRound = this.addInfoForm.ibcRound = this.roomData.ibcRound + ''
    this.addInfoForm.hallLevel = this.roomData.hallLevel
    this.matchRoomName = this.roomData.hallName
    this.groupName = this.groupFormatter(this.roomData)
    this.getDataList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 获取学生列表
    getDataList () {
      getIbcRoomUser(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 添加考生点击事件
    addInfoDialogClick () {
      this.getExamineeList()
    },
    // 获取未分配考生
    getExamineeList () {
      getIbcSignUpUser(this.addInfoForm).then((res) => {
        this.addInfoDialogVisible = true
        this.examineeDataList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 选择未分配考生
    handleSelectionChange (val) {
      var selectUserID = []
      for (const key in val) {
        if (Object.hasOwnProperty.call(val, key)) {
          const element = val[key].enrollmentId
          selectUserID.push(element)
        }
      }
      this.addInfoForm.enrollmentId = selectUserID
    },
    // 选择未分配考生弹窗关闭事件
    addInfoDialogClickClose () {
      this.$refs.addInfoTableRef.clearSelection()
    },
    // 保存新增考生
    saveAddInfoClick () {
      if (!this.addInfoForm.enrollmentId || this.addInfoForm.enrollmentId.length === 0) {
        return this.$message.warning('请选择要添加的考生！')
      }
      addIbcRoomUser(this.addInfoForm).then((res) => {
        this.addInfoDialogVisible = false
        this.$message.success('添加成功')
        this.getDataList()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 重置筛选条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    // 删除学生弹窗提示
    delecteEditDialog (rowData) {
      this.$confirm('是否确认从该考场删除该学生', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delecteGameStudent(rowData)
      }).catch(() => {})
    },
    // 确认删除学生
    async delecteGameStudent (rowData) {
      console.log('this.roomData', this.roomData)
      var formData = {
        id: rowData.id,
        activityType: this.$chnEngStatusCode.ibcActivityType,
        enrollmentId: rowData.enrollmentId,
        hallId: this.roomData.id,
        ibcSession: this.roomData.ibcSession,
        ibcSeason: this.roomData.ibcSeason,
        ibcRound: this.roomData.ibcRound
      }
      deleteIbcRoomUser(formData).then((res) => {
        this.$message.success('删除成功')
        this.getDataList()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 显示移动考生弹窗
    showEditDialog (row) {
      this.editInfoForm.id = row.id
      this.editInfoForm.enrollmentId = row.enrollmentId
      this.editInfoForm.hallLevel = this.roomData.hallLevel
      this.editInfoForm.roomStatus = ''
      console.log('this.editInfoForm', this.editInfoForm)
      this.getRoomListData()
    },
    // 获取考场列表
    getRoomListData () {
      getIbcRoomList(this.editInfoForm).then((res) => {
        this.roomListData = res.data
        this.editInfoDialogVisible = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 移动学生弹窗关闭
    editInfoDialogClose () {
      this.$refs.editInfoFormRef.resetFields()
    },
    // 确认移动点击事件
    editInfo () {
      this.$refs.editInfoFormRef.validate(async valid => {
        if (!valid) return
        moveIbcRoomUser(this.editInfoForm).then((res) => {
          this.$message.success('修改成功')
          this.editInfoDialogVisible = false
          this.getDataList()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    // 导出表格
    exportDownIbcStudentsListForm () {
      var downLoadUrl = this.$http.defaults.baseURL + 'ibc/roomUser/excelIbcRoomUser?hallId=' + this.roomData.id
      this.$downLoadFile.downLoadFileWithFile(this, downLoadUrl)
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    addHandleSizeChange (newSize) {
      this.addInfoForm.pageSize = newSize
      this.getExamineeList()
    },
    addHandleCurrentChange (newCurPage) {
      this.addInfoForm.pageNum = newCurPage
      this.getExamineeList()
    },
    genderFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_gender) {
        if (Object.hasOwnProperty.call(this.dict_gender, key)) {
          const element = this.dict_gender[key]
          if (element.dictValue === data.sex + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    groupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_sspcnGroup) {
        if (Object.hasOwnProperty.call(this.dict_sspcnGroup, key)) {
          const element = this.dict_sspcnGroup[key]
          if (element.dictValue === data.hallLevel) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    userGroupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_sspcnGroup) {
        if (Object.hasOwnProperty.call(this.dict_sspcnGroup, key)) {
          const element = this.dict_sspcnGroup[key]
          if (element.dictValue === data.enrollmentLevel) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    }
  }
}
</script>

<style lang="less" scoped>
.el-table__header .el-table-column--selection .cell .el-checkbox:after {
  content: "全选";
  margin-left: 5px;
}
</style>
